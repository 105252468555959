<template>
  <div class="notice">
    <!--/*      <div style="margin-left: 35%;font-size: 18px">走访满意度</div>*/-->
    <van-image
        round
        width="80px"
        height="80px"
        :src="sex == 1?manImage : womanImage"
    />
    <div class="info"><span>{{name}}</span><span style="font-size: 18px">/</span><span>{{type}}</span></div>
    <!--    <p style="font-size: 12px">满意度评分</p>-->
    <div class="radio-btn-group">
      <div class="radio">
        <input type="radio" name="radio" value="'2'" v-model="visitSatisfaction" id="2" @change="onChange"/>
        <label for="2"><img v-if="visitSatisfaction == '2'" :src="satisfied"/><img v-else :src="satisfied_none"/>非常满意</label>
      </div>
      <div class="radio">
        <input type="radio" name="radio" value="'1'" v-model="visitSatisfaction" id="1" @change="onChange"/>
        <label for="1"><img v-if="visitSatisfaction == '1'" :src="satisfied2"/><img v-else :src="satisfied_none"/>满意</label>
      </div>
      <div class="radio">
        <input type="radio" name="radio" value="'0'" v-model="visitSatisfaction" id="0" @change="onChange"/>
        <label for="0"><img v-if="visitSatisfaction == '0'" :src="dissatisfied"/><img v-else :src="dissatisfied_none"/>不满意</label>
      </div>
    </div>
    <van-button type="info" size="large" :loading="loading" round @click="submit" style="margin-top: 10%;padding: 0 10px;">提交</van-button>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import { mapMutations } from 'vuex'
export default {
  components :{
    topBar
  },
  data() {
    return {
      visitSatisfaction: ' ',
      loading: false,
      name: '',
      type: '',
      sex: '',
      id: 0,
      domain: '',
      manImage: require('@/assets/img/headImgMan.png'),
      womanImage: require('@/assets/img/headImgWoman.png'),
      satisfied: require('@/assets/img/satisfied.png'),
      satisfied2: require('@/assets/img/satisfied.png'),
      dissatisfied: require('@/assets/img/dissatisfied.png'),
      satisfied_none: require('@/assets/img/satisfied_none.png'),
      satisfied2_none: require('@/assets/img/satisfied_none.png'),
      dissatisfied_none: require('@/assets/img/dissatisfied_none.png'),
    };
  },
  created() {
    // console.log(type)
    this.type = this.$route.query.type
    // console.log(this.type)
    this.sex = this.$route.query.sex
    this.name = this.$route.query.name

    //域名默认为闸弄口的域名
    if (this.$route.query.domain) {
      this.domain = decodeURIComponent(this.$route.query.domain + '') + '/cust-api'
    } else {
      this.domain = 'https://hzjgq.hzuht.com/cust-api'
    }
  },
  methods: {
    onChange (e) {
      console.log(e.target.id)
      this.visitSatisfaction = e.target.id
    },
    submit() {
      this.$http({
        url: this.domain + '/wxapp/visit/satisfactionSurvey',
        method: 'post',
        data: this.$http.adornData({
          'visitSatisfaction': this.visitSatisfaction,
          'ids': this.$route.query.id.split(','),
          'isCodePhone': 0
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success ({
            message: data.message,
            duration: 3000,
            onClose: () =>{
              WeixinJSBridge.call('closeWindow')
            }
          })
        } else {
          this.$toast.fail(data.msg);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.van-image {
  margin-top: 50px;
  margin-left: 290px;
}
.info {
  padding-top: 30px;
  text-align: center;
  span {
    font-size: 36px;
  }
  span:nth-child(3) {
    font-size: 32px;
  }
}
p {
  padding-top: 10px;
  margin-left: 300px;
  color: #989898;
}
.van-rate {
  padding-top: 10px;
  margin-left: 200px;
}
.rate {
  padding-top: 20px;
  margin-left: 310px;
  font-size: 30px;
}
.radio-btn-group {
  //display: -webkit-box;
  //display: -webkit-flex;
  //display: -ms-flexbox;
  display: flex;
  margin-left: 50px;
  margin-top: 50px;
  img {
    width: 55px;
    padding-right: 10px;
    padding-bottom: 5px;
  }
}
.radio-btn-group .radio {
  margin: 10px;
}
.radio-btn-group .radio label {
  background: #fff;
  border: 1px solid #ddd;
  padding: 25px 20px;
  border-radius: 50px;
  font-size: 24px;
  cursor: pointer;
  color: #929CAD;
  -webkit-transition: box-shadow 400ms ease;
  transition: box-shadow 400ms ease;
}
.radio-btn-group .radio:nth-child(2) label {
  padding: 25px 40px;
}
.radio-btn-group .radio:nth-child(3) label {
  padding: 25px 28px;
}
.radio-btn-group .radio label:hover {
  //box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.radio-btn-group .radio input[type="radio"] {
  display: none;
}
.radio-btn-group .radio input[type="radio"]:checked + label {
  background: #fff;
  color: #387FF5;
  border-color: #387FF5;
}
.van-button--large {
  width: 90%;
  margin-left: 40px;
}
</style>
